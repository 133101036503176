/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// Custom button group
@import url(./component/table.scss);
@import url(./component/toggle-group.scss);
@import url(./component/paginator.scss);
@import url(./component/form-field.scss);
@import url(./component/demo-btn.scss);
@import url(./component/select.scss);
@import url(./component/search-filter.scss);
@import url(./component/button.scss);
@import url(./component/stepper.scss);
@import url(./component/panel.scss);

body.base .searchbar {
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

body.base .filter-panel-container {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    position: relative;
    color: #bcc0c7;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 10;
    padding: 15px 15px 5px;
    border-radius: 10px;
    .where_clause {
        line-height: 30px;
    }
    .filter-item-field,
    .filter-item-value {
        height: 30px;
        font-size: 12px;
        padding-left: 12px;
        padding: 0.5rem;
        line-height: 20px;
        border: 1px solid #e9ebf0;
        text-transform: capitalize;
        background: rgba(242, 243, 243, 0.5);
    }
    .highlight-text {
        background: none;
    }
}

.filter-container {
    position: relative; /* El contenedor del filtro */
}

.filter-button {
    background-color: #4f46e5 !important;
    color: white !important;
    margin-right: 0.5rem;
}

.filter-button2 {
    background-color: white !important;
    color: #4f46e5 !important;
    margin-right: 0.5rem;
}

.filter-button2:hover {
    background-color: #4f46e5 !important;
    color: white !important;
}
.filter-button2:hover mat-icon {
    color: white !important;
    background-color: #4f46e5 !important;
}

body.base .custom-slide-toggle {
    .mdc-form-field {
        color: #4e4e4e !important;
        font-weight: 500 !important;
    }
}
